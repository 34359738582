@tailwind base;
@tailwind components;
@tailwind utilities;


  @import url('https://fonts.googleapis.com/css2?family=Outfit:wght@300&display=swap');

.bg-about1 {
    background-image: url('/src/components/pages/1.png');
    background-size: contain; 
    background-position: center; 
    background-repeat: no-repeat; 
    height: fit-content;
    width: fit-content;

  
  }
  

  .bg-about2 {
    background-image: url('/src/components/pages/2.png');
    background-size: contain; 
    background-position: center; 
    background-repeat: no-repeat; 
    height: fit-content;
    width: fit-content;

  
  }

  body{
    font-family: 'Outfit', sans-serif;
  }
  